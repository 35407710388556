import { Chip, Grid, Typography, Tooltip } from '@material-ui/core'

import * as CalistaConstant from 'src/constants/CalistaConstant'
import SIActionMenu from 'src/views/shippinginstruction/components/SIActionMenu'
import React from 'react'

import Utils from 'src/views/shippinginstruction/shared/Utils'
import { components } from 'cng-web-lib'
import ManageScheduleTranslationText from '../ShippingInstructionTranslationText'
import LabelValueVerticalSimple from 'src/views/freightbooking/components/LabelValueVerticalSimple'
import SICarrierActionMenu from './SICarrierActionMenu'
import ArchiveIcon from './ArchiveIcon'
const { CngGridItem } = components

const SIDetailTop = (props) => {
  const { si } = props
  const { siStatus, siStatusDesc, carRefNo, shpRefNo, bookingRefNo } = si
  const translatedTextsObjects = ManageScheduleTranslationText()

  let color = Utils.getSIStatusById(siStatus).color

  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--left`}>
            <Chip
              label={
                <Typography className={'boldLabel'} style={{ fontSize: 16 }}>
                  {siStatusDesc !== null ? siStatusDesc.toUpperCase() : ''}

                  {(props.isCarrier ? si.carrierClosed : si.closed) && (
                    <ArchiveIcon />
                  )}
                </Typography>
              }
              style={{ backgroundColor: color }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1`}>
              <LabelValueVerticalSimple
                label={translatedTextsObjects.bookingRefNo + ':'}
                value={bookingRefNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content-bold'}
              />
            </div>
            <div className={`header-box--right-col-2 cta-box`}>
              {props.helpdesk ? (
                <Typography className={'boldLabel'}></Typography>
              ) : (
                <div className={`cng-summary-card--more-actions-wrapper`}>
                  {props.isCarrier ? (
                    !(
                      siStatus.toString() ===
                        CalistaConstant.SI_STATUS_SI_CANCELLED ||
                      siStatus.toString() ===
                        CalistaConstant.SI_STATUS_BL_SURRENDERED
                    ) && (
                      <SICarrierActionMenu
                        si={si}
                        {...props}
                        page={Utils.Page.SIList}
                      />
                    )
                  ) : (
                    <SIActionMenu si={si} {...props} page={Utils.Page.SIList} />
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default SIDetailTop
