import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import BookingActionMenuItem from 'src/views/freightbooking/components/BookingActionMenuItem'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import compliancePathMap from 'src/paths/PathMap_Compliance'
import helpdeskPathMap from 'src/paths/PathMap_Helpdesk'
import FreightBookingApiUrls from 'src/apiUrls/FreightBookingApiUrls'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import { components, constants, useServices } from 'cng-web-lib'
import ActionMenuSIList from './ActionMenuSIList'
import ActionMenuSIDetail from 'src/views/shippinginstruction/components/ActionMenuSIDetail'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import ProxyPrintDialog from './ProxyPrintDialog'
import CommonUtils from 'src/views/common/utils/Utils'
const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { useDefaultNotification }
} = components

function SIActionMenu(props) {
  const history = useHistory()
  const { SendRequest } = useServices()

  const { si, page, refreshAction } = props
  console.log('si si xx', si)

  const SIStatus = si.siStatus || ''

  let actions = SIStatus !== '' ? Utils.getSIStatusById(SIStatus).actions : []
  let actionsH = SIStatus !== '' ? Utils.getSIStatusById(SIStatus).actionsH : []
  let actionsC = SIStatus !== '' ? Utils.getSIStatusById(SIStatus).actionsC : []

  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const { createRecord } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [printDialogOpen, setPrintDialogOpen] = useState(false)
  const [blForPrint, setBlForPrint] = useState()
  const [remainingPrint, setRemainingPrint] = useState()
  const [isPil, setIsPil] = useState(false)
  const [confirmAction, setConfirmAction] = useState(Utils.Action.delete) //need to initiable a value

  const { showNotification } = props
  //console.log(showNotification)
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const gotoMySI = () => {
    history.push({
      pathname: pathMap.MY_SI
    })
  }

  const gotoCreateNewSI = () => {
    history.push({
      pathname: pathMap.CREATE_SI
    })
  }

  const gotoHelpdeskViewSI = () => {
    history.push({
      pathname: helpdeskPathMap.SI
    })
  }

  const SIConfirmMessage = [
    {
      action: Utils.Action.delete,
      title: siTranslatedTextsObject.confirmDeleteSITitle,
      content: siTranslatedTextsObject.confirmDeleteSIContent,
      okMsg: siTranslatedTextsObject.confirmDeleteSIOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    },
    {
      action: Utils.Action.cancel,
      title: siTranslatedTextsObject.confirmCancelSITitle,
      content: siTranslatedTextsObject.confirmCancelSIContent,
      okMsg: siTranslatedTextsObject.confirmCancelSIOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    },
    {
      action: Utils.Action.archive,
      title: siTranslatedTextsObject.confirmArchiveSITitle,
      content: siTranslatedTextsObject.confirmArchiveSIContent,
      okMsg: siTranslatedTextsObject.confirmArchiveSIOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    }
  ]

  function getSIDialogMessageByAction(action) {
    return SIConfirmMessage.find((b) => b.action == action)
  }

  const deleteSI = () => {
    console.log('delete NEW')
    setConfirmAction(Utils.Action.delete)
    setConfirmDialogOpen(true)
  }

  const proceedDeleteSI = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.deleteSuccessMsg} `)
        redirectToMySI()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.DELETE_SI,
      {
        shippingInfoId: si.shippingInfoId,
        freightBookingId: si.freightBookingId
      },
      onSuccess,
      onError
    )
  }

  const viewSI = () => {
    console.log('viewSI clicked')
  }

  const createSI = () => {
    console.log('createSI clicked')
  }

  const trackContainers = () => {
    console.log('trackContainers clicked')
  }

  //cancel si
  // const cancelSI = () => {
  //   console.log('new cancel')
  //   setConfirmDialogOpen(true)
  //   setConfirmAction(Utils.Action.cancel)
  // }

  const confirmDialog = () => {
    console.log('confirmAction--> ' + confirmAction)
    setConfirmDialogOpen(false) //close the dialog first
    switch (confirmAction) {
      // case Utils.Action.cancel:
      //   proceedCancelSI()
      //   break

      case Utils.Action.archive:
        proceedArchiveSI()
        break

      case Utils.Action.delete:
        proceedDeleteSI()
        break

      default:
        break
    }
  }

  function redirectToMySI() {
    console.log('redirectToMySI page', page)
    if (page === Utils.Page.SIDetail) {
      gotoMySI()
    } else {
      //if action is done from menu list, then just refresh the list
      refreshAction()
    }
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  // const proceedCancelSI = () => {
  //   console.log('cancel')
  //   function onSuccess(response) {
  //     console.log('connection-request onSuccess', response)
  //     // setLoading(false)
  //     console.log(response)
  //     if (response.errorMessages != null) {
  //       showErrorNotification(response.errorMessages)
  //     } else {
  //       showSuccessNotification(`${siTranslatedTextsObject.cancelSuccessMsg} `)
  //       redirectToMySI()
  //     }
  //   }

  //   createRecord.execute(
  //     ShippingInstructionApiUrls.CANCEL_SI,
  //     { shippingInfoId: props.si.shippingInfoId },
  //     onSuccess,
  //     onError
  //   )
  // }

  const archiveSI = () => {
    console.log('archive NEW')
    setConfirmDialogOpen(true)
    setConfirmAction(Utils.Action.archive)
  }

  const proceedArchiveSI = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${siTranslatedTextsObject.archiveSuccessMsg} `)
        redirectToMySI()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.ARCHIVE_SI,
      {
        shippingInfoId: si.shippingInfoId,
        freightBookingId: si.freightBookingId
      },
      onSuccess,
      onError
    )
  }

  const manageSI = (prevPage) => (event) => {
    history.push({
      pathname: pathMap.MY_SI_MANAGE_SI,
      state: {
        shippingInfo: props.si,
        prevPage: prevPage,
        action: Utils.Action.edit
      }
    })
  }

  const createPermit = (prevPage) => (event) => {

    let outPermit = false
    let inPermit = false

    if(props.si.placeOfReceiptCode == "SGSIN"){
      outPermit = true
    }else if(props.si.placeOfDeliveryCode == "SGSIN"){
      inPermit = true
    }

    console.log('Create permit')
    history.push({
      pathname: compliancePathMap.CREATE_NEW_PERMIT,
      state: {
        prevPage: prevPage,
        shippingInfo: props.si,
        fromSI: true,
        action: Utils.Action.createPermit,
        inPermit: inPermit,
        outPermit: outPermit
      }
    })
  }

  const updateSI = (prevPage) => (event) => {
    //for draft si, direct to create new si > update draft si
    // props.si.shipmentType = 'FCL'
    // props.si.polCode = 'SGSIN'
    // props.si.podCode = 'CNSHA'
    console.log(si)
    history.push({
      pathname: pathMap.CREATE_SI_UPDATE_SI_DRAFT,
      state: {
        shippingInfo: props.si,
        prevPage: prevPage,
        action: Utils.Action.edit
      }
    })
  }

  const printBL = () => {
    console.log('remaining print', remainingPrint)
    if (CommonUtils.isEmpty(remainingPrint)) {
      let remainingPrintNo =
        props.si.noOfOriginals - props.si.noOfOriginalsPrinted
      setRemainingPrint(remainingPrintNo)
    }
    setBlForPrint(props.si)

    setIsPil(Utils.isThisPartyPIL(props.si.carrierName))

    if (
      remainingPrint < 1 ||
      props.si.noOfOriginals - props.si.noOfOriginalsPrinted < 1
    ) {
      showErrorNotification(siTranslatedTextsObject.proxyPrintNoPrintMessage)
    } else {
      setPrintDialogOpen(true)
    }
  }

  function closePrintDialog() {
    setPrintDialogOpen(false)
    if (page !== Utils.Page.SIDetail) {
      //if action is done from menu list, then just refresh the list
      refreshAction()
    }
  }

  const surrenderBL = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${siTranslatedTextsObject.surrenderSuccessMsg} `
        )
        redirectToMySI()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.SHIPPER_SURRENDER_SI,
      {
        shippingInfoId: si.shippingInfoId,
        freightBookingId: si.freightBookingId
      },
      onSuccess,
      onError
    )
  }

  const copySI = () => {
    setConfirmDialogOpen(false) //close the dialog first

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${siTranslatedTextsObject.copySuccessMsg} ${si.bookingRefNo}`
        )
        history.push({
          pathname: pathMap.CREATE_SI_NEW_SI,
          state: { shippingInfo: response, action: Utils.Action.copy }
        })
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.COPY_SI,
      {
        shippingInfoId: si.shippingInfoId,
        freightBookingId: si.freightBookingId
      },
      onSuccess,
      onError
    )
  }

  const eventHandlers = {
    gotoMySI,
    copySI,
    archiveSI,
    manageSI,
    deleteSI,
    //cancelSI,
    viewSI,
    createSI,
    trackContainers,
    printBL,
    surrenderBL,
    updateSI,
    gotoCreateNewSI,
    gotoHelpdeskViewSI,
    createPermit
  }

  return (
    <div>
      {page === Utils.Page.SIDetail && (
        <ActionMenuSIDetail
          actions={actionsH}
          si={si}
          page={page}
          eventHandlers={eventHandlers}
          helpdesk={props.helpdesk}
        />
      )}
      {(page === Utils.Page.SIList && !props.managePermit) && (
        <ActionMenuSIList
          actions={actions}
          si={si}
          page={page}
          eventHandlers={eventHandlers}
          helpdesk={props.helpdesk}
        />
      )}
      {(page === Utils.Page.SIList && props.managePermit) && (
        <ActionMenuSIList
          actions={actionsC}
          si={si}
          page={page}
          eventHandlers={eventHandlers}
          helpdesk={props.helpdesk}
        />
      )}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={confirmDialog}
        content={getSIDialogMessageByAction(confirmAction).content}
        okMsg={getSIDialogMessageByAction(confirmAction).okMsg}
        cancelMsg={getSIDialogMessageByAction(confirmAction).cancelMsg}
        title={getSIDialogMessageByAction(confirmAction).title}
      />

      <ProxyPrintDialog
        isDialogOpen={printDialogOpen}
        closeDialog={closePrintDialog}
        SendRequest={SendRequest}
        blForPrint={blForPrint}
        remainingPrint={remainingPrint}
        setRemainingPrint={setRemainingPrint}
        isPil={isPil}
      />
    </div>
  )
}

export default SIActionMenu
