import React, { useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import ArchiveButton from 'src/components/button/ArchiveButton.js'
import CancelButtonWithIcon from 'src/components/button/CancelButtonWithIcon.js'
import CopyButton from 'src/components/button/CopyButton.js'
import DeleteButton from 'src/components/button/DeleteButton.js'
import UpdateButton from 'src/components/button/UpdateButton.js'
import RevokeButton from 'src/components/button/RevokeButton'
import IssueButton from 'src/components/button/IssueButton.js'
import PrintBLButton from './PrintBLButton'
import SurrenderBLButton from './SurrenderBLButton'
import CreatePermitButton from 'src/components/button/CreatePermitButton.js'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import HelpDeskTranslationText from 'src/views/helpdesk/HelpDeskTranslationText'
import * as CalistaConstant from 'src/constants/CalistaConstant'
import EditButton from 'src/components/button/EditButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import Utils from '../shared/Utils'

import { components } from 'cng-web-lib'
import ApproveButton from 'src/components/button/ApproveButton'
import DeclineButton from 'src/components/button/DeclineButton'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function ActionMenuSIDetail(props) {
  const { actions, eventHandlers, si, isCarrier, helpdesk, page } = props
  const showPrimary = false
  const translationTextObject = ShippingInstructionTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  console.log(si)

  const BackToButton = () => {
    if (
      si.siStatus != null &&
      si.siStatus.toString() === CalistaConstant.SI_STATUS_PENDING_SUBMISSION
    ) {
      return (
        <CngSecondaryButton onClick={eventHandlers.gotoCreateNewSI}>
          {translationTextObject.back}
        </CngSecondaryButton>
      )
    } else if (isCarrier) {
      return (
        <CngSecondaryButton onClick={eventHandlers.gotoManageSI}>
          {translationTextObject.backToManageSIPage}
        </CngSecondaryButton>
      )
    } else if (helpdesk) {
      // Hide back to view shipping instruction button in shipping instruction details page
      return (
        // <CngSecondaryButton onClick={eventHandlers.gotoHelpdeskViewSI}>
        //   {helpdeskTranslationTextObject.backToViewShippingInstruction}
        // </CngSecondaryButton>
        <></>
      )
    } else {
      return (
        <CngSecondaryButton onClick={eventHandlers.gotoMySI}>
          {translationTextObject.backToMySIPage}
        </CngSecondaryButton>
      )
    }
  }
  return (
    <Box pt={5}>
      <Grid container xs={12} sm={12}>
        <Grid xs={3} sm={3} justify='flex-start'>
          <BackToButton />
        </Grid>
        {!helpdesk ? (
          <Grid container xs={9} sm={9} justify='flex-end' direction='row'>
            {actions.map((action, idx) => {
              switch (action) {
                case Utils.Action.copy:
                  return (
                    <Box pr={2}>
                      <CopyButton
                        isPrimary={showPrimary}
                        onClick={eventHandlers.copySI}
                      />
                    </Box>
                  )
                case Utils.Action.cancel:
                  return (
                    <Box pr={2}>
                      <CancelButtonWithIcon
                        color='secondary'
                        onClick={eventHandlers.cancelSI}
                      />
                    </Box>
                  )
                case Utils.Action.delete:
                  return (
                    <Box pr={2}>
                      <DeleteButton
                        color='secondary'
                        onClick={eventHandlers.deleteSI}
                      />
                    </Box>
                  )

                case Utils.Action.update:
                  return (
                    <Box pr={2}>
                      <UpdateButton
                        onClick={eventHandlers.updateSI(Utils.Page.SIDetail)}
                      />
                    </Box>
                  )
                case Utils.Action.manage:
                  return (
                    <Box pr={2}>
                      <EditButton
                        otherText={uiTranslatedTextsObject.manage}
                        onClick={eventHandlers.manageSI(Utils.Page.SIDetail)}
                      />
                    </Box>
                  )
                case Utils.Action.archive:
                  return !si.closed ? (
                    <Box pr={2}>
                      <ArchiveButton onClick={eventHandlers.archiveSI} />
                    </Box>
                  ) : (
                    <Box pr={2}></Box>
                  )
                case Utils.Action.revokeIssuedBL:
                  return (
                    <Box pr={2}>
                      <RevokeButton
                        onClick={eventHandlers.revokeIssuedBL}
                        otherText={translationTextObject.revokeIssuedBL}
                      />
                    </Box>
                  )
                case Utils.Action.revoke:
                  return (
                    <Box pr={2}>
                      <DeclineButton
                        onClick={eventHandlers.revokeBL}
                        otherText={translationTextObject.revokeBL}
                      />
                    </Box>
                  )
                case Utils.Action.issue:
                  return (
                    <Box pr={2}>
                      <IssueButton
                        onClick={eventHandlers.issueBL}
                        otherText={translationTextObject.issueBL}
                      />
                    </Box>
                  )
                case Utils.Action.approve:
                  return (
                    <Box pr={2}>
                      <ApproveButton
                        onClick={eventHandlers.approveSI}
                        otherText={translationTextObject.approveSI}
                      />
                    </Box>
                  )

                case Utils.Action.reject:
                  return (
                    <Box pr={2}>
                      <DeclineButton
                        onClick={eventHandlers.rejectSI}
                        otherText={translationTextObject.rejectSI}
                      />
                    </Box>
                  )

                case Utils.Action.retract:
                  return (
                    <Box pr={2}>
                      <RevokeButton
                        onClick={eventHandlers.retractSI}
                        otherText={translationTextObject.retractSI}
                      />
                    </Box>
                  )

                case Utils.Action.printbl:
                  return (
                    <Box pr={2}>
                      <PrintBLButton onClick={eventHandlers.printBL} />
                    </Box>
                  )
                case Utils.Action.surrenderbl:
                  return (
                    <Box pr={2}>
                      <SurrenderBLButton
                        isPrimary
                        onClick={eventHandlers.surrenderBL}
                      />
                    </Box>
                  )
                case Utils.Action.createPermit:
                  return (
                    Utils.ableToCreatePermit(si) && (
                      <Box pr={2}>
                        <CreatePermitButton
                          onClick={eventHandlers.createPermit(page)}
                        />
                      </Box>
                    )
                  )
                default:
                  return ''
              }
            })}
          </Grid>
        ) : (
          <Grid
            container
            xs={9}
            sm={9}
            justify='flex-end'
            direction='row'
          ></Grid>
        )}
      </Grid>
    </Box>
  )
}

export default ActionMenuSIDetail
