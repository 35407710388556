import {
  Box,
  Card,
  CardActionArea,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'

import * as calistaConstant from 'src/constants/CalistaConstant'
import LabelValueVerticalSimple from 'src/views/freightbooking/components/LabelValueVerticalSimple'
import LogoWithTitleVertical from 'src/views/freightbooking/components/LogoWithTitleVertical'
import ManageScheduleTranslationText from '../ShippingInstructionTranslationText'
import PortDetailComponent from 'src/views/freightbooking/components/PortDetailComponent'
import React from 'react'
import SIDetailBottom from './SIDetailBottom'
import SIDetailTop from './SIDetailTop'
import Utils from 'src/views/common/utils/Utils'
import { components } from 'cng-web-lib'
import helpdeskPathMap from 'src/paths/PathMap_Helpdesk'
import moment from 'moment'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import { useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const { CngGridItem } = components

const initialValues = Object.freeze({})

const InfoIconButton = withStyles((theme) => ({
  root: {
    padding: 0
  }
}))(IconButton)

const FormBody = (props) => {
  const translatedTextsObject = ManageScheduleTranslationText()
  const { si } = props
  const history = useHistory()
  console.log(si)

  let shipperShortName = si.partyIdName
  let showTooltip = false
  if (si.partyIdName && si.partyIdName.length > 5) {
    shipperShortName = si.partyIdName.substring(0, 5).trim() + '...'
    showTooltip = true
  }

  let carrierShortName = si.carrierName
  let isCarrierNameCut = false
  if (si.carrierName && si.carrierName.length > 5) {
    carrierShortName = si.carrierName.substring(0, 5).trim() + '...'
    isCarrierNameCut = true
  }

  const gotoReviewSI = () => {
    console.log('click')
    console.log(si)
    console.log(props.helpdesk)

    if (props.helpdesk) {
      history.push({
        pathname: helpdeskPathMap.SI_DETAILS,
        state: {
          shippingInfoId: si.shippingInfoId,
          freightBookingId: si.freightBookingId,
          siStatus: si.siStatus
        }
      })
    } else {
      history.push({
        pathname: props.draftSI
          ? pathMap.CREATE_SI_VIEW_SI_DRAFT
          : pathMap.MY_SI_VIEW_SI,
        state: {
          shippingInfoId: si.shippingInfoId,
          freightBookingId: si.freightBookingId,
          siStatus: si.siStatus
        }
      })
    }
  }

  return (
    <Card className={`summary-list-item--card`}>
      <SIDetailTop si={si} {...props} />
      <CardActionArea
        onClick={gotoReviewSI}
        className={`cng-summary-card--body-box`}
      >
        <Grid container xs={12} className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={12} sm={1}>
            <LogoWithTitleVertical
              logoSrc={`${process.env.PUBLIC_URL}/static/images/carriers/${si.carrierNameOth}.svg`}
              defaultImg={`${process.env.PUBLIC_URL}/static/images/carriers/genericCarrier.svg`}
              withTooltip={isCarrierNameCut}
              longName={si.carrierName}
              shortName={carrierShortName}
            />
          </CngGridItem>

          <CngGridItem xs={12} sm={5} style={{ paddingLeft: 20 }}>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.vesselName}
                value={si.vesselName}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.voyageNumber}
                value={si.voyageNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            {si.siStatus !== null &&
              si.siStatus + '' === calistaConstant.SI_STATUS_BL_ISSUED && (
                <Box pt={0.5}>
                  <LabelValueVerticalSimple
                    label={translatedTextsObject.availablePrintRemaining}
                    value={si.noOfOriginals - si.noOfOriginalsPrinted}
                    defaultValue='-'
                    labelStyle={'frb-card-label'}
                    valueStyle={'frb-card-content'}
                  />
                </Box>
              )}

            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.shipperRefNo}
                value={si.shipperRefNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
          </CngGridItem>

          <CngGridItem xs={12} sm={6}>
            <Grid container>
              <CngGridItem xs={12} sm={12}>
                <Grid container>
                  <CngGridItem xs={12} sm={6}>
                    <PortDetailComponent
                      title={translatedTextsObject.placeOfReceipt}
                      portCode={si.placeOfReceiptCode}
                      portName={si.placeOfReceiptCodeDesc}
                      date={si.placeOfReceiptEtd}
                    />
                  </CngGridItem>

                  <CngGridItem xs={12} sm={6}>
                    <PortDetailComponent
                      title={translatedTextsObject.placeOfDelivery}
                      portCode={si.placeOfDeliveryCode}
                      portName={si.placeOfDeliveryCodeDesc}
                      date={si.placeOfDeliveryEta}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
              <CngGridItem xs={12} sm={12}>
                <Grid container>
                  <CngGridItem xs={12} sm={6}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.bookingDateAndTime}
                      // value={si.bookingSubmittedDate}
                      value={Utils.formatDate(
                        si.bookingSubmittedDate,
                        Utils.FORMAT_DATE_TIME,
                        Utils.DASH
                      )}
                      defaultValue='-'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.blApplicationType}
                      value={si.billOfLadingAppTypeDesc}
                      defaultValue='-'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={12}>
                    <LabelValueVerticalSimple
                      label={translatedTextsObject.blrefNo}
                      value={si.billOfLadingNo}
                      defaultValue='-'
                      labelStyle={'frb-card-label'}
                      valueStyle={'frb-card-content'}
                    />
                  </CngGridItem>
                </Grid>
              </CngGridItem>
            </Grid>
          </CngGridItem>
        </Grid>
      </CardActionArea>
      <SIDetailBottom
        dockey={si.freightBookingId}
        updatedDate={si.updatedDate}
        hazardous={si.hazardous}
        createdDate={si.createdDate}
        directSi={si.directSi}
      />
    </Card>
  )
}

const SIDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default SIDetailComponent
